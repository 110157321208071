.layout {
  background: #373B44;  /* fallback for old browsers */
  background: linear-gradient(90deg, rgba(33,105,172,1) 0%, rgba(22,70,134,1) 56%, rgba(22,70,134,1) 100%);
  background-size: "cover";
}

.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.ant-layout-sider {
  background: #082154;
}

.menu-logo{
  display: block;
  margin: 0 auto;
}

.menu-logo-collapsed {
  display: block;
  margin: 0 auto;
  width: 50px;
}


.menu-logo-vision{
  display: block;
  margin: 0 auto;
  width: 249px;
  position: fixed;
  bottom: 48px;
}

.menu-logo-vision-collapsed {
  display: block;
  margin: 0 auto;
  width: 79px;
  position: fixed;
  bottom: 48px;
}